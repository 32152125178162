<template>
  <Page full>
    <Grid
      type="client"
      title="Clients + leads"
      :multiple="true"
      :isMain="true"
      :showDefaultFilterPresets="true"
      :order="[['client_time_last_modified', 'DESC']]"
      :filters="{
        client_status: '!i&&!d&&!x&&!h'
      }"
      :filterText="{
        client_status: 'Active'
      }"
    />
  </Page>
</template>

<script>
import Grid from '../ui/Grid/Grid.vue'
import PageMixin from '../mixins/Page'

export default {
  mixins: [PageMixin],
  components: { Grid }
}
</script>
